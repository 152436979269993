import { useCallback, useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {
    const savedCallback = useRef(callback);
    const intervalIdRef = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // handle tick
    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        };

        if (delay !== null) {
            intervalIdRef.current = setInterval(tick, delay);
        }

        const id = intervalIdRef.current;
        return () => {
            clearInterval(id);
        };
    }, [delay]);

    // handle unmount
    useEffect(() => {
        const id = intervalIdRef.current;
        return () => {
            clearInterval(id);
        };
    }, []);

    const resetInterval = useCallback(() => {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = setInterval(savedCallback.current, delay);
    }, [delay]);

    return resetInterval;
};

export default useInterval;
