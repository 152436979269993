import React, { memo } from 'react';

import { imageLoader } from '@utils/asset';
import Image from 'next/image';

import AdPixel from './AdPixel';
import { BannerImageLink } from './Banner.styles';

const BannerImage = ({ banner }) => {
    const { properties: { url, name, link, pixel, isad } = {}, image } = banner || {};

    if (!image) {
        return null;
    }

    const isAd = isad === 'true';

    const href = link || url || '#';

    return (
        <BannerImageLink href={href} {...(isAd && { target: '_blank', rel: 'noopener' })}>
            <Image
                src={image}
                loader={imageLoader}
                alt={name}
                layout="fill"
                objectFit="cover"
                //TODO nextjs 12 update - https://nextjs.org/docs/basic-features/image-optimization#priority
                priority
                loading="eager"
            />
            {pixel && <AdPixel pixel={pixel} />}
        </BannerImageLink>
    );
};

export default memo(BannerImage);
