import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/system';

import Box from '@ui/Box';
import IconButton from '@ui/IconButton';
import Link from '@ui/link/Link';

const bannerHeight = theme => ({
    height: 200,
    [theme.breakpoints.up('sm')]: {
        height: 300,
    },
    [theme.breakpoints.up('md')]: {
        height: 300,
    },
    [theme.breakpoints.up('lg')]: {
        height: 350,
    },
    [theme.breakpoints.up('xl')]: {
        height: 400,
    },
});

export const BannerImageLink = styled(Link)(({ theme }) => ({
    ...bannerHeight(theme),
    position: 'relative',
    display: 'block',
    width: '100%',
    '& img': {
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
    },
}));

export const BannerImageStyled = styled('div')(({ theme }) => ({
    ...bannerHeight(theme),
    position: 'relative',
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing(6),
    '& img': {
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
    },
}));

export const Container = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(8),
    position: 'relative',
}));

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    ...bannerHeight(theme),
    borderRadius: theme.shape.borderRadius,
}));

export const BaseButton = styled('div')({
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    '&:hover .MuiIconButton-root': {
        opacity: 1,
    },
});

export const PrevButton = styled(BaseButton)({
    left: 0,
});

export const NextButton = styled(BaseButton)({
    right: 0,
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    opacity: 0,
    transition: 'all 0.2s ease',
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.appBar,
    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },
}));
