import React, { useState } from 'react';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import useInterval from '@components/app/hooks/useInterval';

import { Container, NextButton, PrevButton, StyledIconButton } from './Banner.styles';
import BannerImage from './BannerImage';

const Banner = ({ banners, interval = 10000 }) => {
    const [activeStep, setActiveStep] = useState(0);

    const maxSteps = banners?.length || 0;
    const isSingleBanner = banners?.length === 1;

    const nextClickHandler = () => {
        handleNext();
        resetInterval();
    };

    const backClickHandler = () => {
        handleBack();
        resetInterval();
    };

    const handleNext = () => {
        setActiveStep(prevActiveStep => (maxSteps - 1 === prevActiveStep ? 0 : prevActiveStep + 1));
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => (0 === prevActiveStep ? maxSteps - 1 : prevActiveStep - 1));
    };

    const resetInterval = useInterval(() => {
        handleNext();
    }, interval);

    if (!banners && banners?.length === 0) {
        return null;
    }

    return (
        <Container>
            {!isSingleBanner && (
                <PrevButton>
                    <StyledIconButton size="large" onClick={backClickHandler}>
                        <KeyboardArrowLeft />
                    </StyledIconButton>
                </PrevButton>
            )}
            <BannerImage banner={banners[activeStep]} />
            {!isSingleBanner && (
                <NextButton>
                    <StyledIconButton size="large" onClick={nextClickHandler}>
                        <KeyboardArrowRight />
                    </StyledIconButton>
                </NextButton>
            )}
        </Container>
    );
};

export default Banner;
