/* eslint-disable @next/next/no-img-element */
import React from 'react';

import { styled } from '@mui/system';

export const PixelContainer = styled('div')({
    position: 'absolute',
    pointerEvents: 'none',
});

const AdPixel = ({ pixel }) => {
    if (!pixel || !pixel.includes('https://')) {
        return null;
    }

    return (
        <PixelContainer>
            <img src={pixel} alt="" width={1} height={1} />
        </PixelContainer>
    );
};

export default AdPixel;
